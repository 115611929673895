const guest = '/webapi/guest'

import axios from '@/plugins/axios'

const guestApi = {
    guestLogin: () => axios({
        url: `${guest}/login`,
        method: 'post',
    }),
    productInquiry: (data) => axios({
        url: `${guest}/product/inquiry`,
        method: 'post',
        data
    }),
}

export default guestApi;
