<template>
  <a-config-provider :locale="locale" :theme="{
      token: {
        colorPrimary: '#101820',
      },
    }" :direction="plugins.getLocal('lang') === 'ar' ? 'rtl' : 'ltr'">
    <h1 class="hideContent"> Falcons | Luxury Product Auctions & Private Sales | falcons.com </h1>
    <router-view />
    <baned-block ref="banedRef"/>
    <mobile-block ref="mobileRef"/>
    <eruda-component/>
  </a-config-provider>
</template>

<script setup>
import { nextTick } from 'vue'
import enUS from 'ant-design-vue/es/locale/en_US';
import BanedBlock from "@/components/homepageComponent/banedBlock.vue";
import MobileBlock from "@/components/homepageComponent/mobileBlockComponent.vue";
import {ref, watch,onMounted, onBeforeUnmount } from "vue";
import store from "@/store";
import plugins from "@/plugins/plugins";
import { isFromApp } from '@/plugins/tools'
import { useMobile } from '@/plugins/tools'
import { useRoute } from 'vue-router'
import ErudaComponent from "@/components/erudaComponent/erudaComponent.vue";
import GuestManager from "@/utils/guestManager";

const { mobile } = useMobile()
const locale = enUS
// banedAuction
const banedRef = ref(null)
const initWidth = ref()
watch(() => store.getters.getBanedAuction, (newVal,oldValue) => {
  console.log(newVal,oldValue)
  if (newVal) {
    banedRef.value.open = true
    store.commit('updateBanedAuction',false)
  }
})
const route = useRoute()
const isAuctionLive = ref(false);
// isAuctionLive
watch(() => route, (newVal) => {
  if (!newVal) return
  const { name } = newVal
  isAuctionLive.value = name === 'livestreaming'
}, { immediate: true, deep: true })
const mobileRef = ref(null)
onMounted(async ()=>{
  await nextTick()
  initWidth.value = window.innerWidth
  if(mobile.value){
    window.addEventListener('resize', handleResize);
    if(!isFromApp()){
      mobileRef.value.open = false
    }
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
})

const handleResize = () => {
  // if auction live and mobile don't reload
  if(isAuctionLive.value && mobile.value) return
  if(window.innerWidth !== initWidth.value) {
    location.reload()
  }
}

GuestManager.init();

</script>
<style scoped>
</style>

<style scoped>
.hideContent{
  display: none;
}
</style>

