import plugins from "@/plugins/plugins";
import router from '@/router';
import GuestManager from "@/utils/guestManager";
const isLogin = (() => {
    if(plugins.getLocal('userToken') === undefined || plugins.getLocal('userToken') === null) {
        router.push('/login')
    } else{
        return true
    }
})

export const isLocalLogin = (() => {
    if(plugins.getLocal('userToken') === undefined || plugins.getLocal('userToken') === null) {
        return false
    } else{
        return true
    }
})

export const LOGIN_STATE={
    USER:'user',
    GUEST:'guest',
    NONE:'none'
}
export const userOrGuestLogin = (() => {
    if(isLocalLogin()){
        return LOGIN_STATE.USER;
    }
    else if(GuestManager.isGuestLogin()){
        return LOGIN_STATE.GUEST;
    }
    else {
        return LOGIN_STATE.NONE;
    }
})

export default isLogin
