let product = '/webapi/product';
let conf = '/webapi/conf'
let collect = '/webapi/collect'
let notification = '/webapi/notification'
let search = '/webapi/search'
let location = '/webapi/location'
let order = '/webapi/order'
let guestOrder = '/webapi/guest/order'
let homepage = '/webapi/homepage'
let crypto = '/webapi/crypto'
let pay = '/webapi/pay'
let vaulted = '/webapi/vaulted'
let shipment = '/webapi/shipment'
let exchangeRate = '/webapi/exchangeRate'

import axios from '@/plugins/axios'
const productApi = {
      /***
     * getFilter List
     * @returns {AxiosPromise}
     *
     */
      getFilterData: () => axios({
        url: `${product}/getAllCategoryCorrelation`,
        method: 'get',
    }),
    /***
     * getCategory List
     * @returns {AxiosPromise}
     *
     */
    getCategory: () => axios({
        url: `${product}/getAllCategoryCorrelation`,
        method: 'get',
    }),
    /*
       getCategory Tree
     */
    getCategoryTree: (params) => axios({
        url: `${product}/getCategoryTree`,
        method: 'get',
        params
    }),
    /*
       get hamburger Category List
     */
    getHamburgerCategory: () => axios({
        url: `${homepage}/web/getHamburgerCategoryBanner`,
        method: 'get'
    }),
    /*
    get home page configs
    */
    getHomePageConfig: () => axios({
        url: `${homepage}/web/landingPage/allBanners`,
        method: 'get'
    }),
    /***
     * sumbit product
     * @param data{gender,categoryId,brandId,fineness,pricingMode}
     * @returns {AxiosPromise}
     */
    submitProduct: (data) => axios({
        url: `${product}/submit`,
        method: 'post',
        data
    }),

    /***
     * sumbit search content
     * @param data{searchContent}
     * @returns {AxiosPromise}
     */
    submitSearch:(data) => axios({
        url: `${search}/searchBox`,
        method: 'post',
        data
    }),

        /***
     * get search result
     * @param data{searchContent}
     * @returns {AxiosPromise}
     */
    getSearchResult:(data) => axios({
            url: `${search}/v2/product`,
            method: 'post',
            data
        }),
    getNewFilterList: (data) => axios({
        url: `${search}/product/v3/aggregate`,
        method: 'POST',
        data
    }),
    /***
     * sumbit product
     * @param data{vaultId,pricingMode}
     * @returns {AxiosPromise}
     */
    submitVault: (data) => axios({
        url: `${product}/submitForVault`,
        method: 'post',
        data
    }),
    /***
     * get Product List
     * @returns {AxiosPromise}
     */
    getListPage: (data) => axios({
        url: `${product}/listPage`,
        method: 'post',
        data
    }),
    /***
     * get Product Search List
     * @returns {AxiosPromise}
     */
    searchProductList: (data) => axios({
        url: `${search}/product`,
        method: 'post',
        data
    }),
    /***
     * get SPU Product Search List
     * @returns {AxiosPromise}
     */
    searchSPUProductList: (data) => axios({
      url: `${search}/product/spu`,
      method: 'post',
      data
  }),
    soldProductList: (data) => axios({
      url: `${search}/product/sold`,
      method: 'post',
      data
  }),

  //get search diaglog recommend list
  getRecommendList: () => axios({
    url: `${search}/recommendList`,
    method: 'get',
  }),

    getSearchBanner: () => axios({
      url: `${search}/getBanner`,
      method: 'get',
    }),
    /***
     * get Product andAggregate List
     * @returns {AxiosPromise}
     */
    andAggregate: (data) => axios({
        url: `${search}/product/andAggregate`,
        method: 'post',
        data
    }),
    /**
     * get all country
     */
    getCountryList: (data) => axios({
        url: `${location}/country/list`,
        method: 'post',
        data
    }),
    /**
     * get all brand
     */
    getAllBrand: () => axios({
        url: `${product}/getAllBrand`,
        method: 'get',
    }),
    /**
     * get all category
     */
    getAllCategory: (params) => axios({
        url: `${product}/getParentCategory`,
        method: 'get',
        params,
    }),
    /**
     * get dictionary
     */
    getDictionary: (data) => axios({
        url: `${conf}/getDicList?groupName=${data}`,
        method: 'get'
    }),
    /***
     * get Product Detail
     * @returns {AxiosPromise}
     */
    getProductDetail: (data) => axios({
        url: `${product}/details`,
        method: 'post',
        data
    }),
    /***
     * get SPU Product Detail
     * @returns {AxiosPromise}
     */
    getSPUProductDetail: (data) => axios({
      url: `${product}/spu/details`,
      method: 'post',
      data
  }),
    /**
     * accept quotation
     */
    acceptQuotation: (data) => axios({
        url: `${product}/acceptQuotation`,
        method: 'post',
        data
    }),
    /**
     * submit tracking number
     */
    uploadTrackingNumber: (data) => axios({
        url: `${product}/uploadTrackingNumber`,
        method: 'post',
        data
    }),
    /**
     * add to collect
     * bizId：productId,lotId,auctionId
     * type:1-product 2-lot 3-auction
     */
    addCollcet: (data) => axios({
        url: `${collect}/add`,
        method: 'post',
        data
    }),
    /**
     * remove from collect
     * params:id
     */
    removeCollect: (data) => axios({
        url: `${collect}/remove`,
        method: 'delete',
        data
    }),
    /**
     * query collect
     * @param data
     * @returns {*}
     */
    searchCollections: (data) => axios({
        url: `${collect}/itemFilterPageQuery`,
        method: 'post',
        data,
    }),
    /**
     * query collect lot
     * @param data
     * @returns {*}
     */
    searchCollectLots: (data) => axios({
        url: `${collect}/lotFilterPageQuery`,
        method: 'post',
        data,
    }),
    /**
     * query collect auction
     * @param data
     * @returns {*}
     */
    searchCollectAuction: (data) => axios({
        url: `${collect}/auctionPageQuery`,
        method: 'post',
        data,
    }),
    /**
     * add to cart
     *
     */
    addCart: (data) => axios({
        url: `${product}/cart/add`,
        method: 'post',
        data
    }),
    /**
     * remove from cart
     * @param data
     * @returns {*}
     */
    removeCart: (data) => axios({
        url: `${product}/cart/v2/remove`,
        method: 'delete',
        data
    }),
    getCartSummary: () => axios({
      url: `${product}/cart/getSummary`,
      method: 'get'
    }),
    /**
     * get message count
     */
    getMessageCount: () => axios({
        url: `${notification}/countUnread`,
        method: 'get',
    }),
    /**
     * get message list
     */
    getMessageList: (data) => axios({
        url: `${notification}/pageQuery`,
        method: 'post',
        data,
    }),
    /**
     * mark as read
     */
    markAsRead: (data) => axios({
        url: `${notification}/markAsRead`,
        method: 'post',
        data,
    }),
    /**
     * mark as all read
     */
    markAllRead: (data) => axios({
        url: `${notification}/markAllAsRead`,
        method: 'post',
        data,
    }),
    /**
     * search cart list
     */
    searchCart: (data) => axios({
        url: `${product}/cart/v2/pageQuery`,
        method: 'post',
        data
    }),
    /**
     * get price details
     */
    getPriceDetails: (data) => axios({
        url: `${product}/cart/priceDetails`,
        method: 'post',
        data
    }),
    /*
    * get search suggest
     */
    getSearchSuggest: (data) => axios({
        url: `${search}/suggest`,
        method: 'post',
        data
    }),
    /*
    * get search lost list
     */
    getLotsList: (data) => axios({
        url: `${search}/searchLotProduct`,
        method: 'post',
        data
    }),

    /**
     * generate confirm Order details
     */
    generateConfirmOrder: (data) => axios({
        url: `${order}/v2/generateConfirmOrder`,
        method: 'post',
        data
    }),
    /**
     * get auction lots list
     */
    getAuctionLotsList: (data) => axios({
        url: `${search}/lotProductOfAuction`,
        method: 'post',
        data
    }),
    /**
     * product make offer
     */
    makeOffer: (data) => axios({
        url: `${product}/offer/submit`,
        method: 'post',
        data
    }),
    /**
     * get offer list
     */
    getOfferList: () => axios({
        url: `${product}/offer/list`,
        method: 'GET'
    }),
    /**
     * accept offer
     */
    acceptOffer: (data) => axios({
        url: `${product}/offer/accept`,
        method: 'post',
        data
    }),
    /**
     * reject offer
     */
    rejectOffer: (data) => axios({
        url: `${product}/offer/reject`,
        method: 'post',
        data
    }),
    /**
     * homepage get banner list
     */
    getBannerList: () => axios({
        url: `${homepage}/getAllBanners`,
        method: 'GET'
    }),
    getAllCategoryBanners: () => axios({
      url: `${homepage}/getAllCategoryBanners `,
      method: 'GET'
    }),
    getCategoryBannersvideo:(categoryId,brandId, scene)=>axios({
        url: `${homepage}/web/getCategoryBanners`,
        method: 'GET',
        params: { // 使用 params 属性来传递查询参数
             categoryId,
             brandId,
             scene
        }
    }),
    scenonpage:(categoryId,brandId,scene)=>axios({
        url: `${homepage}/web/getsConceptCategoryBanners`,
          method: 'GET',
          params: { // 使用 params 属性来传递查询参数
            categoryId,
            brandId,
            scene
       }
      }),
    /**
     * homepage get product promotion list
     */
    getHomepagePromotionProductList: (params) => axios({
        url: `${product}/promotion/homepage/list`,
        method: 'GET',
        params,
    }),


    /**
     * get product left filter list
     * @param data
     * @returns {*}
     */
    getFilterList: (data) => axios({
        url: `${search}/product/aggregate`,
        method: 'POST',
        data
    }),
    /**
     * get spu product left filter list
     * @param data
     * @returns {*}
     */
    getSConceptFilterList: (data) => axios({
      url: `${search}/product/spu/aggregate`,
      method: 'POST',
      data
  }),
    /**
     * get product promotion list
     * @param data
     * @returns {*}
     */
    getPromotionList: (data) => axios({
        url: `${product}/promotion/search/list`,
        method: 'POST',
        data
    }),
    getSPUDetailPromotionList: (data) => axios({
        url: `${product}/spu/promotion/details/list`,
        method: 'POST',
        data
    }),
    getCategoryBrandPic:(params) => axios({
        url:`${homepage}/web/getCategoryBrandPic`,
        method: 'GET',
        params,
    }),
    getDetailPromotionList: (data) => axios({
      url: `${product}/promotion/details/list`,
      method: 'POST',
      data
  }),
    /**
     * submit vip phonenumber
     * @param data
     * @returns {*}
     */
    submitVip: (data) => axios({
        url: `${product}/vip/submit`,
        method: 'POST',
        data
    }),

    /**
     * get address List
     */
    getAddressList: (data) => axios({
        url: `${location}/address/list`,
        method: 'post',
        data
    }),
    /**
     * add address
     */
    addAddress: (data) => axios({
        url: `${location}/address/add`,
        method: 'post',
        data
    }),
    /**
    * edit address
    */
    editAddress: (data) => axios({
        url: `${location}/address/edit`,
        method: 'post',
        data
    }),
    /**
     * delete address
     */
    deleteAddress: (data) => axios({
        url: `${location}/address/delete`,
        method: 'post',
        data
    }),
    /**
     * get public key
     * @param data
     * @returns {*}
     */
    getPublicKey: (data) => axios({
        url: `${crypto}/publicKey`,
        method: 'get',
        data
    }),
    /**
     * submit order
     * @param data
     * @returns {*}
     */
    submitOrder: (data) => axios({
        url: `${order}/v2/submitOrder`,
        method: 'post',
        data
    }),
    newSubmitOrder: (data) => axios({
        url: `${order}/v3/submitOrder`,
        method: 'post',
        data
    }),
    /**
     * cancel order
     * @param data
     * @returns {*}
     */
    cancelOrder: (data) => axios({
        url: `${order}/manualCancelOrder`,
        method: 'post',
        data
    }),

    /**
     * pay order
     * @param data
     * @returns {*}
     */
    payOrder: (data) => axios({
        url: `${order}/payOrder`,
        method: 'post',
        data
    }),
    /**
     * order notify
     * @param data
     * @returns {*}
     */
    payNotify: (data) => axios({
        url: `${order}/payNotify`,
        method: 'post',
        data
    }),
    /**
        * order details
        * @param data
        * @returns {*}
        */
    getOrderDetail: (data) => axios({
        url: `${order}/orderDetail`,
        method: 'post',
        data
    }),
        /**
        *guest order details
        * @param data
        * @returns {*}
        */
        getGuestOrderDetail: (data) => axios({
            url: `${guestOrder}/orderDetail`,
            method: 'post',
            data
        }),
      /**
        * orderDetailConfirmAuction
        * @param data
        * @returns {*}
        */
      getOrderDetailConfirmAuction: (data) => axios({
        url: `${order}/orderDetailConfirmAuction`,
        method: 'post',
        data
    }),
    /**
        * Check order validity time
        * @param data
        * @returns {*}
        */
    checkOrderTime: (data) => axios({
        url: `${order}/OrderValidityTime `,
        method: 'post',
        data
    }),
    /**
     * rollback status
     */
    rollbackStatus: (data) => axios({
        url: `${product}/rollbackStatus`,
        method: 'post',
        data
    }),
    /**  order page query (Purchase Page)
    *  @param data
    *  @returns {*}
    */
    getOrderList: (data) => axios({
        url: `${order}/pageQuery`,
        method: 'post',
        data
    }),

    /**
       * Get shipping prices based on address
       * @param data
       * @returns {*}
       */
    getShippingDetails: (data) => axios({
        url: `${order}/v2/shippingCompute`,
        method: 'post',
        data
    }),
    getGenerateConfirmOrderDetails: (data) => axios({
        url: `${order}/v3/generateConfirmOrder`,
        method: 'post',
        data
    }),
    /**
     * accpet makeoffer list
     */
    acceptOfferList: (data) => axios({
        url: `${product}/offer/batchAccept`,
        method: 'post',
        data
    }),
    /**
     * reject makeoffer list
     */
    rejectOfferList: (data) => axios({
        url: `${product}/offer/batchReject`,
        method: 'post',
        data
    }),
    /**
     * get shipping order list
     */
    generateShippingOrder: (data) => axios({
        url: `${order}/generateShippingOrder`,
        method: 'POST',
        data
    }),
    /**
     * choose pick up
     */
    choosePickUp: (data) => axios({
        url: `${product}/choicePickUp`,
        method: 'POST',
        data
    }),
    /**
     * choose pick up withdraw
     */
    choosePickUpWithdraw: (data) => axios({
        url: `${product}/withdraw`,
        method: 'POST',
        data
    }),
    /**
     * get aggregateLotProduct
     */
    aggregateLotProduct: (data) => axios({
        url: `${search}/aggregateLotProduct`,
        method: 'POST',
        data
    }),
    /*
    **  get lot promotion list
     */
    getpromotionLotProduct: (data) => axios({
        url: `${search}/promotionLotProduct `,
        method: 'post',
        data
    }),
    /**
     * payout page query 'wallet'
     */
    getWalletList: (data) => axios({
        url: `${pay}/payout/pageQuery`,
        method: 'POST',
        data
    }),
    /**
     * payout page query 'wallet'
     */
    getWalletInfo: (data) => axios({
        url: `${pay}/wallet/info`,
        method: 'POST',
        data
    }),
    /**
     *  get last payout account  'wallet'
     */
    getLastPayoutInfo: (data) => axios({
        url: `${pay}/payout/lastAccount`,
        method: 'POST',
        data
    }),

    /**
     *  create a payout  'wallet'
     */
    confirmPayout: (data) => axios({
        url: `${pay}/payout/create`,
        method: 'POST',
        data
    }),

    /**
     *  vaulted page query
     */
    getVaultedList: (data) => axios({
        url: `${vaulted}/pageQuery`,
        method: 'POST',
        data
    }),

    /**
     * vaulted ask to return multipleChoice
     */
    vaultedReturn: (data) => axios({
        url: `${vaulted}/multipleChoice`,
        method: 'POST',
        data
    }),

    /**
     * vaulted product info order submit
     */
    submitVaultedOrder: (data) => axios({
        url: `${vaulted}/submitOrder`,
        method: 'POST',
        data
    }),
    /*
    ** track parcel by number
     */
    trackParcel: (data) => axios({
        url: `${shipment}/getTrackHistory?courierNum=${data}`,
        method: 'GET'
    }),

    /**
     * get state
     */
    getStateList: (data) => axios({
        url: `${location}/state/list`,
        method: 'post',
        data
    }),

    /**
     * get city
     */
    getCityList: (data) => axios({
        url: `${location}/city/list`,
        method: 'post',
        data
    }),

    /**
     * get collections total
     */
    getCollectionsCount: (data) => axios({
        url: `${collect}/collectNumber`,
        method: 'get',
        data
    }),
    /**
     * get collections list buynow
     */
    getCollectionsList: (data) => axios({
        url: `${collect}/itemPageQuery`,
        method: 'post',
        data
    }),
    /**
     * get collections list buynow
     */
    getCollectionsLotsList: (data) => axios({
        url: `${collect}/lotPageQuery`,
        method: 'post',
        data
    }),
    /**
     * download invoice
     */
    downloadInvoice: (data) => axios({
        url: `${order}/invoiceDownload?orderNum=${data}`,
        method: 'get',
        responseType: "blob",
    }),
    /**
     * update auction order
     */
    updateAuctionOrder: (data) => axios({
        url: `${order}/updateOrderAuction`,
        method: 'post',
        data
    }),
    /**
     * get AED price
     */
    getAEDPrice: (data) => axios({
        url: `${exchangeRate}/calculateWithCurrency`,
        method: 'POST',
        data
    }),
    /**
     * get Collection buynow FilterList
     */
    getItemFilterList :(data) => axios({
        url: `${collect}/itemAggregate`,
        method: 'post',
        data
    }),
    /**
     * get Collection lots FilterList
     */
    getLotFilterList :(data) => axios({
        url: `${collect}/lotAggregate`,
        method: 'post',
        data
    }),
     /**
     * Order Auction vat
     */
     getOrderAuctionVat :(data) => axios({
        url: `${order}/orderVat`,
        method: 'post',
        data
    }),
     /**
     * get Confg CountryList
     */
     getConfigCountryList :(data) => axios({
        url: `${conf}/getConfKV?key=${data}`,
        method: 'get',
    }),
    /**
     *  delete order
     */
    deleteOrder :(data) => axios({
        url: `${order}/deleteOrder?id=${data}`,
        method: 'get',
    }),
    // cybersourcePay: (data) => axios({
    //   url: 'https://testsecureacceptance.cybersource.com/silent/pay',
    //   method: 'post',
    //   data
    // })

    changeNum :(data) => axios({
        url: `${product}/cart/changeNum`,
        method: 'post',
        data
    }),
    getConceptFilterList :(data) => axios({
        url: `${collect}/productSpuAggregate`,
        method: 'post',
        data
    }),
    getConceptCollectionsList: (data) => axios({
        url: `${collect}/productSpuPageQuery`,
        method: 'post',
        data
    }),
    sendEnquire:(params)=>axios({
        url:`${product}/inquiry`,
        method:'get',
        params,
    }),
    bankTransfer: (data) =>
        axios({
            url: `/webapi/auction/join/bankTransfer`,
            method: 'post',
            data,
        }),

    bankTransferStatus: (params) =>
        axios({
            url: `/webapi/auction/join/bankTransfer/queryStatus`,
            method: 'get',
            params,
        }),

        /**
     * pay order
     * @param data
     * @returns {*}
     */
        newPayOrder: (data) =>
            axios({
                url: `${order}/v2/payOrder`,
                method: 'post',
                data,
            }),

}
export default productApi
