/*
 * @Author: yangpeng
 * @Date: 2024-07-25 15:48:47
 * @LastEditors: yangpeng
 * @LastEditTime: 2024-08-13 21:43:39
 * @FilePath: \falcons_ecommerce_web\falcons_ecommerce-web\src\i18n\langs\landingpage.js
 * @Description:
 */
const ar = {
	royaloak: 'مزاد فالكونز الافتتاحي',
	offshore: '',
	exploreInfo:'التسجيل من هنا',
	landingDes: '28 سبتمبر، في برج العرب، دبي',
	landingDes2:'شاركوا بالمزايدة على الحقائب والساعات والسيارات',
	landingDiscover: 'التسجيل من هنا',
	landingDiscoverWap: 'ادخلوا عالَم جمع المقتنيات',
	landingDiscoverSpotlight: 'تسوَّقوا الساعات',
	landingPremier: 'الوجهة المثلى للمزادات والبيع الخاص',
	landingPremier1: 'للمقتنيات الراقية',
	landingPremier2: 'اكتشفوا فالكونز',
	landingPremierWap: 'الوجهة المثلى للمزادات والبيع',
	landingPremierWap1: 'الخاص للمقتنيات الراقية',
	landingPremierWap2: '',
	landingvideo1: 'الساعات',
	landingvideo2: 'حقائب اليد',
	landingvideo3: 'المقتنيات',
	landingvideo4: 'ذي إس كونسيبت',
	landingSpotlight: 'تسليط الضوء على',
	landingCartier: 'كارتييه',
	landingEpitomizing: 'تجسيدٌ للفن والتراث والتفرُّد.',
	landingShop: 'اكتشفوا المزيد',
	landingCollection: 'ادخلوا عالَم جمع المقتنيات',
	landingEpitomizingWap: 'تجسيدٌ للفن والتراث والتفرُّد.',
	landingEpitomizingWap1: '',
	landingSpotlightDiscover: 'اكتشفوا فالكونز',
	landingHotDiscover: 'اكتشفوا المزيد',
	sell1: 'بيعوا على',
	sell1Mobile: 'بيعوا على موقع فالكونز',
	sell2: 'موقع فالكونز',
	sell3: 'استمتعوا بمعدَّل عمولة منخفض بنسبة 15% من فالكونز، مع ضمان الشراء، والدفع خلال 14 يوماً فقط، وخدمة الاستلام الحصرية لكبار الشخصيات.',
	sell4:'في تحقيق أفضل قيمة لقِطَعكم.',
	sell6: 'ابدأوا البيع معنا',
	sellWap1: 'استمتعوا بمعدَّل عمولة منخفض بنسبة 15% من فالكونز، مع ضمان الشراء، والدفع خلال 14 يوماً فقط، وخدمة الاستلام الحصرية لكبار الشخصيات.',
	sellWap2: 'أفضل قيمة للقطع الخاصة بكم.',
	sellWap3: '',
	sellWap4: '',
	CarDetail1: 'التزامُنا بالفحص الشامل والدقيق',
	CarDetail2:
		'تخضع كل سيارة في مجموعتنا لعملية فحص شاملة من قبل فريق الخبراء المهرة لدينا،',
	CarDetail3:
		'ما يضمن أنَّ كل سيارة تلبِّي أعلى معايير التميُّز قبل أن تصل إليك.',
	CarDetail4: '',
	carDeatilWap1: 'ضمانُنا للأصالة',
	carDeatilWap2: '',
	carDeatilWap3: 'التزامُنا بالأصالة لا يتزعزع، فقبل أن تصل أيُّ قطعة إلى يديك، تخضع',
	carDeatilWap4: 'للفحص الدقيق من قِبل فريقنا من الخبراء في التحقُّق من الأصالة، كلٌّ',
	carDeatilWap5: 'منهم معتَمَدٌ في مجاله. إنَّهم يتحقَّقون بدقَّةٍ من أصالة كلِّ قطعة، ما',
	carDeatilWap6: 'يضمن أنَّ ما تتلقاهُ استثنائيٌ بجدارة.',
	carDeatilWap7: '',
	memorabiliaDetail1:'ضمانُنا للأصالة',
	memorabiliaDetail2:'التزامُنا بالأصالة لا يتزعزع، فقبل أن تصل أيُّ قطعة إلى يديك، تخضع للفحص الدقيق من قِبل فريقنا من الخبراء في التحقُّق من الأصالة، كلٌّ منهم معتَمَدٌ في مجاله. إنَّهم يتحقَّقون بدقَّةٍ من أصالة كلِّ قطعة، ما يضمن أنَّ ما تتلقاهُ استثنائيٌ بجدارة.',
	priceOnRequest: 'السعر عند الطلب',
	carModel: 'الموديل',
	carYear: 'سنة الموديل',
	carMileage: 'عدد الإسطوانات',
	carCylinder: 'عداد المسافات',
	landingAPPAREL:'الملابس',
	landingKICKS: 'الأحذية',
	CollectiveText:' كوليكتف',
	Aestination:'الوجهةُ المثلى لجامعي المقتنيات.',
	scrollToExplore:'تسوقوا جميع القِطَع',
	falconsText:'فالكونز',
	christmasText:'تخفيضات عيد الميلاد',
	sale20percentOff:'خصم حتى 20%'
};
const en = {
	royaloak: 'Falcons',
	falconsText:"Falcons",
	christmasText:"Christmas Sale",
	sale20percentOff:'UP TO 20% OFF',
	CollectiveText:' Collective',
	offshore: 'Inaugural Auction',
	Aestination:'A destination for every collector.',
	landingDes: 'September 28, Burj Al Arab, Dubai',
	landingDes2:'Bid on Handbags, Timepieces and Automobiles',
	exploreInfo:'Register Here',
	scrollToExplore:'SHOP ALL',
	landingDiscover: 'DISCOVER MORE',
	landingDiscoverSpotlight: 'DISCOVER MORE',
	landingDiscoverWap: 'DISCOVER MORE',
	landingPremier: '“The premier destination for auctions and',
	landingPremier1: 'private sales of luxury collectibles.”',
	landingPremier2: 'DISCOVER FALCONS',
	landingPremierWap: '“The premier destination for',
	landingPremierWap1: 'auctions and private sale of',
	landingPremierWap2: 'luxury collectibles.”',
	landingvideo1: 'TIMEPIECES',
	landingvideo2: 'HANDBAGS',
	landingvideo3: 'SPORTS CARDS',
	landingvideo4: 'THE S CONCEPT',
	landingSpotlight: 'SPOTLIGHT ON',
	landingCartier: 'Cartier',
	landingEpitomizing: 'Epitomizing artistry, heritage & exclusivity.',
	landingShop: 'DISCOVER MORE',
	landingCollection: 'DISCOVER MORE',
	landingEpitomizingWap: 'Epitomizing artistry, heritage',
	landingEpitomizingWap1: '& exclusivity.',
	landingSpotlightDiscover: 'DISCOVER FALCONS',
	landingHotDiscover: 'DISCOVER FALCONS',
	sell1: 'Sell with',
	sell1Mobile: 'Sell with Falcons',
	sell2: 'Falcons',
	sell3: 'Enjoy Falcons\' 15% low commission rate, with a buyout guarantee, 14 day payout, and exclusive VIP pickup service.',
	sell4: 'best value of your items.',
	sell6: 'SELL WITH US',
	sellWap1: 'Enjoy Falcons\' 15% low commission rate, with a buyout guarantee, 14 day payout, and exclusive VIP pickup service.',
	sellWap2: '',
	sellWap3: 'Sell with Falcons and our professional team will help',
	sellWap4: 'you get the best value of your items.',
	CarDetail1: 'Our Comprehensive Inspection Commitment',
	CarDetail2: `Every automobile in our collection undergoes a comprehensive inspection process by our team of skilled experts,`,
	CarDetail3:
		' ensuring that every vehicle meets the highest standards of excellence before it reaches you.',
	CarDetail4: 'nothing short of exceptional.',
	carDeatilWap1: 'Our Authentication',
	carDeatilWap2: 'Guarantee',
	carDeatilWap3:
		'Our commitment to authenticity is unwavering. Before any item',
	carDeatilWap4:
		'reaches your hands, it undergoes rigorous scrutiny by our team',
	carDeatilWap5: 'of expert authenticators. They meticulously verify the',
	carDeatilWap6:
		'genuineness of every piece, ensuring that what you receive is',
	carDeatilWap7: 'nothing short of exceptional.',
	memorabiliaDetail1:'Our Authentication Guarantee',
	memorabiliaDetail2:'Our commitment to authenticity is unwavering. Before any item reaches your hands, it undergoes rigorous scrutiny by our team of expert authenticators. They meticulously verify the genuineness of every piece, ensuring that what you receive is nothing short of exceptional.',
	priceOnRequest: 'PRICE ON REQUEST',
	carModel: 'Model',
	carYear: 'Model Year',
	carMileage: 'Mileage',
	carCylinder: 'Cylinder',
	landingAPPAREL:'APPAREL',
	landingKICKS: 'KICKS'
};

export { ar, en };
