export function numberToCurrencyNo(value,type,floatPart = '00',currency = 'USD'){
    if(!value) return '0.00'
    if(type === 'price'){
        value = value / 100
    }
    let isPositive = value > 0
    const intPart = Math.trunc(value)
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    const value2Array = value.toString().split('.')
    if(value2Array.length === 2) {
        floatPart = value2Array[1].toString()
        if (floatPart.length === 1) {
            if(type === 'price'){
                return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart + '0 ' + currency
            } else{
                return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart + '0'
            }
        } else {
            if(type === 'price') {
                return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart + ' ' + currency
            } else{
                return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart
            }
        }
    } else {
        if(type === 'price') {
            if(floatPart === ''){
                return (isPositive ? '' : '-') + intPartFormat  + ' ' + currency
            } else {
                return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart + ' ' + currency
            }
        } else{
            return (isPositive ? '' : '-') + intPartFormat + '.' + floatPart
        }
    }
}