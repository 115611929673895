import guestApi from '@/api/guest'
import {isLocalLogin} from "@/plugins/isLogin";
import CryptoJS from 'crypto-js'
import store from "@/store";
import API from "@/api/product.js";

const TOKEN_KEY = 'falcons_guest_token';
const TOKEN_KEY_DATE = 'falcons_guest_date';
const VISIT_TIME = 'falcons_guest_reload_token';

const secretKey = 'B67BnnyD3ndX37NdxCUhPvX4wu6SEZu5'
const iv = CryptoJS.enc.Utf8.parse("WMUWbTuLwKYmVunQ"); // 偏移量


class GuestManager {
    static encryptGuestValue(value) {
        // return CryptoJS.AES.encrypt(value, secretKey).toString();
        return CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(secretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC, // 使用CBC模式
            padding: CryptoJS.pad.Pkcs7
        }).toString()
    }

    static decryptValue(value) {
        const bytes = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(secretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return bytes.toString(CryptoJS.enc.Utf8);
    }

    static writeToken2Local(token) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
        localStorage.setItem(TOKEN_KEY_DATE, (new Date()).getTime().toString());
        console.log(`write guest token ${token}`);
    }

    static async getPublicKey(){
        try {
          const res = await API.getPublicKey('publicKey');
          store.commit('updatePublicKey', res.data)
        } catch (error) {
          console.log(error)
        }
      }

    static async guestLogin() {
        console.log(`guestLogin`);
        if (isLocalLogin()) {
            return false;
        }

        if (!localStorage.getItem(TOKEN_KEY)) {
            const res = await guestApi.guestLogin();
            if (res.data) {
                this.writeToken2Local(res.data.toString());
                this.getPublicKey()
                return true;
            }

            return false;
        } else {
            return false;
        }
    }

    static async updateLoginToken() {
        console.log(`updateLoginToken`);
        if (this.isGuestLogin()) {
            const res = await guestApi.guestLogin();
            if (res.data) {
                this.writeToken2Local(res.data.toString());
            }
        }
    }

    static isGuestLogin() {
        if (!isLocalLogin() && localStorage.getItem(TOKEN_KEY)) {
            return true
        } else {
            return false
        }
    }

    static isTokenOutOfDate() {
        const now = (new Date()).getTime();
        const tokenDate = parseInt(localStorage.getItem(TOKEN_KEY_DATE));
        if (now - tokenDate > 604800000) {
            return true;
        } else {
            return false;
        }
    }

    static clearLogin() {
        console.log(`clearLogin`);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_KEY_DATE);
    }

    // clear token
    static setupUnloadListener() {
        window.addEventListener("beforeunload", () => {
            // last visit time in falcons
            localStorage.setItem(VISIT_TIME, (new Date().getTime()).toString());
        });
    }

    static reloadOrOpenLogic=()=>{
        const lastVisitTime = localStorage.getItem(VISIT_TIME);
        const currentTime = new Date().getTime();

        // get span time after last visit,if less than 10 second,means reload
        if (lastVisitTime && currentTime - lastVisitTime < 30000) {
            console.log('reload page');
        } else {
            console.log('reopen page');
            this.clearLogin();
        }
    }

    static init() {
        try {
            if (this.isGuestLogin() && this.isTokenOutOfDate()) {
                this.clearLogin();
            }

            this.reloadOrOpenLogic();
            this.setupUnloadListener();
        } catch (ex) {
            console.error(ex)
        }
    }
}


export default GuestManager;

// TokenManager.getToken().then((res) => {
//     window['falconsGuestToken'] = res.token;
//     console.log('FalconsGuestToken:', res.token);
// });

