import axios from 'axios'
import store from "@/store";
import plugins from "@/plugins/plugins";
import router from '@/router';
import errorMsg from '@/plugins/errorMsg'
import i18n from "@/i18n";
import { message } from 'ant-design-vue';

// we3auth
import web3auth  from "@/plugins/web3auth";

const API = axios.create({
    headers: {
        // 'Content-Type': 'application/json;charset=utf-8',
        'lang':plugins.getLocal('lang') || 'en',
        'country':plugins.getLocal('country') || 'AE',
    },
    timeout: 30000, // request timout
    baseURL:process.env.VUE_APP_API_URL,
});
API.interceptors.request.use(
    config => {
        config.headers.token = plugins.getLocal('userToken') || plugins.getLocal('falcons_guest_token')
        config.headers.lang = plugins.getLocal('lang') || 'en'
        return config //To handle the return data of Config, then send request to EP
    },
    error => { // To execute the code when error occurs
        // console.log(error)
        return Promise.reject(error)
    }
)
API.interceptors.response.use(function (response) {
    // When the status code equals 2xx,then produce the callback on succucess, Response means the result of succucess as well
    if(response.data.code === 0 || response.status === 200){//To handle the specific logic by dividing the 0 || 200 status
        if(response.data.code === 330006 || response.data.code === 330001){
            //auction 被 ban或者登录被ban
            store.commit('updateBanedAuction',true)
            return response.data
        }
        else if( response.data.code in errorMsg ){
            message.error(errorMsg[response.data.code])
        }else{
            return response.data
        }
    } else {
        //抛错
        message.error(i18n.global.t('errorText'))
        return Promise.reject(response.data)
    }
}, function (error) {
    // When the status code equals 2xx,then produce the callback on failure, Response means the result of failure as well
    if (error?.response?.status === 401) {
        store.commit('reset')
        plugins.deleteLocal('userToken')
        plugins.deleteLocal('userInfo')
        sessionStorage.removeItem('metamaskAccount')
        sessionStorage.removeItem('avatarUrl')
        if(window.location.pathname === '/home'){
            window.location.reload()
        } else{
            if(web3auth && web3auth.connected){
              web3auth.logout()
              sessionStorage.removeItem('address')
            }
            router.push('/login')
        }
    }
    return Promise.reject(error)
})
export default API